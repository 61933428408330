import React, { Component } from "react";
import foto1 from "../assets/images/foto1.jpeg";
import foto2 from "../assets/images/foto2.jpeg";

export class LastEvents extends Component {
    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column">
                    <h1 className="page-title p-3 fs-4">Eventos Anteriores</h1>
                    <div className="container">
                        <div className="d-flex flex-column aling-items-center">
                            <img src={foto1} alt="banner evento" width="auto" />
                            <h4 className="pt-4 pb-5">
                                Vale presente em seminário Técnico no Crea-MG
                            </h4>
                        </div>
                        <div className="container">
                            <img src={foto2} alt="banner evento" width="auto" />
                            <h4 className="pt-4 pb-5">
                                Vale presente em seminário Técnico no Crea-MG
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LastEvents;
