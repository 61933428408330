import React, { Component } from "react";
import floripa from "../assets/images/floripa.jpeg";
import vale1 from "../assets/images/vale1.jpeg";
import vale2 from "../assets/images/vale2.jpeg";
class Home extends Component {
    render() {
        return (
            <div className="d-flex page-container">
                <div className="d-flex flex-column">
                    <h1 className="page-title p-3 fs-4">Nossos Eventos</h1>
                    <div className="container mb-3">
                        <div className="d-flex flex-column aling-items-center">
                            <img src={floripa} alt="banner evento" width="auto" />
                            <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href="https://forms.gle/Mf6XbjMr2CCLwPVT9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4>
                        </div>
                        <hr class="hr" />
                        <div className="d-flex flex-column aling-items-center">
                            <img src={vale1} alt="banner evento" width="auto" />
                            {/* <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4> */}
                        </div>
                        <hr class="hr" />
                        <div className="d-flex flex-column aling-items-center">
                            <img src={vale2} alt="banner evento" width="auto" />
                            {/* <h4 className="pt-4 pb-5">
                                Garanta sua{" "}
                                <a
                                    href=""
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    inscrição aqui
                                </a>
                                !
                            </h4> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
